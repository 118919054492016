// Include only variables and mixins to prevent unused CSS
@import '~bootstrap/scss/mixins/color-mode';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import 'styles/bootstrap-forms';

@import '~bootstrap/scss/bootstrap-utilities';

// Import rc-select styles (extends some Bootstrap form styles)
@import 'styles/rc-select';

// need transitions for accordion to work
@import '~bootstrap/scss/mixins/transition';
@import '~bootstrap/scss/transitions';

// Import button styles
@import '~bootstrap/scss/mixins/buttons';
@import '~bootstrap/scss/buttons';

// Include the Grid
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/grid';

// Include the Containers
@import '~bootstrap/scss/mixins/container';
@import '~bootstrap/scss/containers';

// Include the Modal
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/backdrop';
@import '~bootstrap/scss/modal';

// Include the Alerts
@import '~bootstrap/scss/mixins/alert';
@import '~bootstrap/scss/alert';

// Include the Accordion
@import '~bootstrap/scss/accordion';

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
