.Wrapper {
  position: relative;
  .Hint {
    position: absolute;
    top: 100%;
    left: 1.4em;
    margin-top: 0.4em;
    z-index: 1;
    font-size: 1.4em;
    white-space: nowrap;
    color: white;
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.1));
    padding: 0.4em 2em 0.4em 1em;
    border-radius: 0.4em;
    :global(.svg-inline--fa) {
      margin-right: 0.4em;
      position: relative;
      bottom: 0.4em;
    }
  }
}

.RoleLabel {
  color: white;
  strong {
    display: block;
    margin-top: -0.2em;
  }
  div {
    font-size: 1.1em;
    line-height: 1;
  }
}

.Select {
  // While partner data is loading, the dropdowns may show the previously selected partnerId and
  // marketplaceId values. Blur these while the data is loading from the API, then fade the
  // "human" values in.
  :global(.rc-select-selection-item) {
    transition: all 0.2s ease;
  }
  &:global(.rc-select-loading) {
    :global(.rc-select-selection-item),
    :global(.rc-select-selection-placeholder) {
      opacity: 0.8;
      filter: blur(4px);
    }
  }
}

.PartnerOption {
  font-size: 0.8em;
  line-height: 1.2;
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, .3) inset;
  padding: 0.6em 0.8em;
  &:last-child {
    box-shadow: none;
  }
  ul {
    margin: 0 0 0.4em;
    padding: 0 0 0 1.4em;
  }
}

.PartnerAlternateNames {
  :global(.rc-select-selection-item) & {
    display: none;
  }
  margin: 0.2em 0 0 0.4em;
  font-size: 0.9em;
}

.Spinner {
  color: white;
  font-size: 18px;
  margin-right: 10px;
  margin-left: -8px;
}

.Loading {
  color: white;
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
}