.App {
  display: grid;
  grid-template:
    "header header" auto
    "proxy proxy" 1fr / 1fr 200px;
  /*
    "proxy log" 1fr / 1fr 200px;
  */
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.Header {
  grid-area: header;
}

.ProxySizing {
  grid-area: proxy;
  position: relative;
  background: #333;
  .Loader {
    width: 50vw;
    height: 10vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Proxy {
    background: transparent;
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.Log {
  grid-area: log;
  background: #2a2b6f;
  padding: 1em;
  font-family: monospace;
  color: white;
}
