@use "sass:color";

.Nav {
  display: flex;
  gap: 2em;
  align-items: center;
  background: #080941;
  font-weight: 600;
  padding: 10px;
  border-bottom: 1px solid color.mix(#080941, white, 75%);
  font-size: 0.72em;
}

.menu {
  box-sizing: border-box;
  position: relative;
  color: #fff;
  * {
    box-sizing: border-box;
  }
  h3 {
    margin-top: 0;
  }
  .menu-icon {
    width: 10px;
    cursor: pointer;
  }
  .menu-items {
    position: absolute;
    top: 35px;
    left: -10px;
    width: 400px;
    padding: 1em;
    background-color: #080941;
    z-index: 9;
    dl {
      display: flex;
      flex-wrap: wrap;
      * {
        margin: 0;
        padding: 0;
      }
      dt {
      flex: 25%;
      }
      dd {
        flex: 75%;
        ul {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.Logo {
  margin-right: 15px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  > span {
    display: inline-block;
  }
  .gatekeeper-logo {
    display: flex;
    gap: .5em;
    position: relative;
    right: -15px;
    justify-content: flex-end;
  }
}

.ThrasioLogo {
  $width: 9.2em;
  $height: 2em;
  display: block;
  margin: -0.2em 0 -0.6em -0.4em;
  vertical-align: middle;
  width: $width;
  height: $height;
  background-size: $width $height;
  background-color: #55fff5;
  mask: url(./thrasio.svg) no-repeat center;
  mask-size: $width $height;
  + span {
    display: block;
    font-size: 0.8em;
    text-align: right;
    margin: 0 0.4em -0.2em 0;
  }
}
