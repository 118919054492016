.Container {
  color: #55fff5;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > svg {
    width: 300px;
    height: 300px;
    margin: 0 -18px -18px 0;
  }
}
.Backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, .3);
}
.Animation {
  margin-top: -280px;
  margin-bottom: -50px;
  width: 240px;
  height: 240px;
}
