.Loading {
  background: #080941;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #55fff5;
  p {
    margin: 0.4em -0.4em 0 0;
  }
  svg {
    font-size: 2em;
  }
}
